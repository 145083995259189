import {
  IonButton,
  IonCol,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import loyaltyIcon from '../../assets/images/loyalty.svg';
import orderIcon from '../../assets/images/order.svg';
import { NormalText, SmallText, Spacer, StrongText, Subtitle } from '../../components/common';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isDefined } from '../../lib/utils';
import { showToast } from '../../store/actions';
import moment from 'moment';

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, restaurants, dispatch, ...rest }) => {
    const [page, setpage] = useState(1);
    const type = rest.type || 'order';
    const isOrderPage = type === 'order';
	  const { disableHistoryPagination } = getConfig()?.flags;
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    const doRefresh = (event, getTransactionHistory) => {
      setTimeout(() => {
        event.detail.complete();
        getTransactionHistory(page, !isOrderPage, isOrderPage);
      }, 1000);
    };
    
    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };

    const orderAgain = (past_orders, option) => {
      const restaurant = restaurants.filter(
        (restaurant) => restaurant.id === past_orders.restaurant_id,
      );
      if (restaurant.length > 0) {
        Basket.reset();
        Basket.setDeliveryOption(option);
        forwardTo(option.route, { selectedRestaurant: past_orders });
      } else {
        dispatch(showToast('This restaurant is no longer receiving orders.', 'warning'));
      }
    };

    const showMore = () => {
      const newPage = page + 1;
      setpage(newPage);
      getTransactionHistory(newPage, !isOrderPage, isOrderPage);
    };

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={`history-content ${type !== '' ? `${type}-tab` : ''}`}>
          {(transactionHistory || []).filter(item => item.item?.payment_token == 'Pay to the driver' || item.item?.payment_token == 'Pay on collection' || (item.item?.status?.toUpperCase() !== 'CREATED' && item.item?.status?.toUpperCase() !== 'NEW')).length === 0 ? (
            type === 'order' ? (
              <div className="no-data-history">
                {/* <img alt=" " src={orderIcon} /> */}
                <NormalText>
                  {__(
                    'Looks like you haven’t made any orders yet. Once you do your updates will appear here.',
                  )}
                </NormalText>
              </div>
            ) : (
              <div className="no-data-history">
                {/* <img alt=" " src={loyaltyIcon} /> */}
                <NormalText>
                  {__(
                    'Looks like you haven’t earned any loyalty yet. Once you do your updates will appear here.',
                  )}
                </NormalText>
              </div>
            )
          ) : (
            <>
              <IonGrid class="box-wrapper">
                {transactionHistory.map((i, index) => {
								  const { stamp_power, transaction_date, label, item, location_name, is_points, is_stamps, is_vouchers, reason_balance } = i;
                  const withoutZ = transaction_date.replace('Z', '');
                  const transDate = Basket.getDate(withoutZ);
                  let orderLabel = Basket.getOrderType(i.item);
                  let option = (delivery || []).find((d) => d.id === orderLabel);
                  let orderId = i && i.item ? ' #' + i.item.id : '';
                  let status = item && item.status ? item.status : '';
                  status = status.toUpperCase();
                  const paymentToken = item ? item.payment_token : '';
                  let locationName =
                    location_name && location_name !== ''
                      ? location_name
                      : i && i.item
                        ? i.item.restaurant_name
                        : '';
                  const showItem =
                    paymentToken === 'Pay on collection' ||
                    paymentToken === 'Pay to the driver' ||
                    (status.toUpperCase() !== 'CREATED' && status.toUpperCase() !== 'NEW');
                  const orderCreatedAt = item && item?.created_at ? moment(item.created_at).format(`D MMM YYYY [${__('at')}] LT`) : '';
                  return (
                    showItem && (
                      <IonRow key={index} className="history-item">
                        <IonCol size={type === 'order'?'7':'8'} onClick={() => handleRowClick(item)}>
                          {!item?.is_gift ? (
                            <>
                              <SmallText>{locationName}</SmallText>

                              <NormalText className="primary-color break-spaces uppercase block">
                                {type === 'order' ? (option ? __(option.label) + orderId : '') : __(label)}
                              </NormalText>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {item?.is_asap ?
                                  <SmallText>{__('ASAP')}</SmallText>
                                  :
                                  <>
                                    <SmallText style={{ marginRight: '4px' }}>{__(transDate.format(`ddd DD MMM YYYY [${__('at')}]`))}</SmallText>
                                    <SmallText>{item?.additional_data?.named_time_slots_data ? item.additional_data?.named_time_slots_data : transDate.format('LT')}</SmallText>
                                  </>


                                }
                              </div>

                            </>
                          ) : (
                            <>
                              <NormalText className="primary-color break-spaces uppercase block">
                                {__('eGift Voucher')} {orderId}
                              </NormalText>
                              {orderCreatedAt ? <SmallText className=" break-spaces">{orderCreatedAt}</SmallText> : null}
                            </>
                          )}
                        </IonCol>
                        {type === 'order' ? (
                          !item?.is_gift &&
                          !Basket.has_multi_basket && (
                            <IonCol
                              size="5"
                              style={{
                                alignSelf: 'center',
                                textAlign: 'end',
                              }}
                              className="order-button-col-width"
                            >
                              <IonButton color="primary" className="small-btn" onClick={() => orderAgain(item, option)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-repeat"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3"></path>
                                  <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3"></path>
                                </svg>
                                {__('Reorder')}
                              </IonButton>
                            </IonCol>
                          )
                        ) : (
                          <IonCol
                            style={{
                              alignSelf: 'center',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                            className='transaction-points-column'
                          >
                            <div className="transaction-points-wrapper">
                              <NormalText className={is_vouchers ? 'bold' : stamp_power < 0 ? 'bold danger-color' : 'bold success-color'}>
                                {is_vouchers ? (
                                  <>{__(reason_balance)}</>
                                ) : (
                                  <>
                                    {stamp_power < 0 ? '-' : '+'}
                                    {Math.abs(stamp_power)}
                                  </>
                                )}
                              </NormalText>
                              {is_stamps ? (
                                <SmallText>{Math.abs(stamp_power) == 1 ? __('stamp') : __('stamps')}</SmallText>
                              ) : is_points ? (
                                <SmallText>{Math.abs(stamp_power) == 1 ? __('point') : __('points')}</SmallText>
                              ) : null}
                            </div>
                          </IonCol>
                        )}
                      </IonRow>
                    )
                  );
                })}
              </IonGrid>
              <Spacer size={1} />
              {disableHistoryPagination || (transactionHistory || []).length < 10 ? null : (
                <IonButton onClick={() => showMore()} fill="clear" className="link underlined">
                  {__('Show more')}
                </IonButton>
              )}
            </>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
