import {Capacitor } from '@capacitor/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonCheckbox,
  IonAlert,
  IonAvatar,
  IonIcon,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import {
  NormalText,
  Spacer,
  FieldError,
  Title,
  StrongText,
  SmallText,
} from '../../components/common';
import { validateForm, isDefined, isWebConfig, forwardTo, getDefaultRoute, isNativeAndroid } from '../../lib/utils';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { updateProfile, setModal, loading, deleteUser, logout, updateProfileImage } from '../../store/actions';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import ValidateButton from '../../components/validateButton';
import Loading from '../../components/spinner';
import defaultImg from '../../assets/images/gray-avatar.png';
import Mobiscroll from '../../components/mobiscroll';
import cameraIcon from '../../assets/images//camera-icon.svg';
import { Camera, CameraResultType } from '@capacitor/camera';
import './index.css';
import MobileInput from '../../components/mobileInput';

const { configS3 } = getConfig();
const { DatePicker, SelectOption } = Mobiscroll;
const isWeb = () => Capacitor.getPlatform() === 'web';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      mobile: this.props.profile.mobile || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      imageFile: null,
      is_subscribed: this.props.profile.is_subscribed || false,
      formErrors: {},
      deleteAvatarImageAlert: false,
      locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      closeMyAccount: false,
      saveAccountIsOpen: false,
      mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
			mobile_value: this.props.profile.mobile_value ? this.props.profile.mobile_value : this.props.profile.mobile == this.props.profile.mobile_code ? '' : this.props.profile.mobile,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      email: { type: 'email', required: false },
      mobile: { type: 'tel', required: false },
      locale: { type: 'text', required: false },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({
        birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      });
    }
    if (this.props.profile.locale !== prevProps.profile.locale) {
      this.setState({
        locale: this.props.profile.locale || getConfig().localization.defaultLocale,
      });
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
    if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
      this.setState({ is_subscribed: this.props.profile.is_subscribed });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    this.props.dispatch(beforeCloseTimePicker());
  };

  handleLanguageInput = (event, data) => {
    this.setState({ locale: data.getVal() });
  };

  async takePicture() {
    let image;
    if (!isWeb()) {
      try {
        image = await Camera.getPhoto({
          quality: 50,
          allowEditing: true,
          resultType: CameraResultType.Uri
        });
        this.setState({
          profile_image_url: image.webPath,
        });
        this.updateProfileImage(image.webPath, image.format);
      } catch (error) {
        console.error('Error capturing photo:', error);
      }
    } else {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.capture = 'camera';
      try {
        input.onchange = (event) => {
          const selectedFile = event.target.files?.[0];
          if (selectedFile) {
            const imageFile = event.target.files[0];
            let reader = new window.FileReader();
            reader.readAsDataURL(imageFile);
            let extension = imageFile.name.split('.').pop();
            reader.onloadend = () => {
              this.setState({
                profile_image_url: reader.result,
              });
              this.updateProfileImage(reader.result, extension);
            };
          } else {
            console.log('Image is not selected!')
          }
        };
        input.click();
      } catch (error) {
        console.error('Error selecting image:', error);
      }
    }
  };

  getMimeType = (extension) => {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      default:
        return undefined;
    }
  };

  handleSave(fromAlert = false) {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        first_name,
        last_name,
        email,
        mobile,
        birthday,
        profile_image_url,
        imageFile,
        is_subscribed,
        locale,
        mobile_code, mobile_value 
      } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        email: email,
				mobile: getConfig().flags.hasMobileCountryCode ? (mobile_value ? `${mobile_code}${mobile_value}` : '') : mobile,
        birthday: birthday,
        profile_image_url: !this.props.profile.profile_image_url ? null : profile_image_url,
        is_subscribed,
        locale,
        mobile_code: mobile_value ? mobile_code : '',
				mobile_value,
      };
      if (
        (!this.props.profile.birthday || this.props.profile.birthday == '') &&
        !fromAlert &&
        birthday !== ''
      ) {
        this.setState({ saveAccountIsOpen: true });
        return;
      }
      this.props.dispatch(updateProfile(profile));
    }
  }

  updateProfileImage(profile_image_url, extension) {
    if(profile_image_url, extension){
      const imageName = `${configS3.imageNamePrefix}${Date.now()}${this.props.profile.id}`;
      fetch(profile_image_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new window.File([blob], imageName, {
            type: this.getMimeType(extension),
          });
          this.props.dispatch(updateProfileImage(file));
          this.setState({ imageFile: null });
        });
    } 
  }

  removeProfileImage = () => {
    const { profile } = this.props;
    const profile_image_url = profile.profile_image_url;
    this.handleAvatarDeleteAlert(false);
    if (profile_image_url) {
      let imageName = profile_image_url.split('/');
      if (imageName.length > 0) {
        imageName = imageName[imageName.length - 1];
        this.props.dispatch(updateProfile({ profile_image_url: null }));
        this.setState({ imageFile: null });
      }
    }
  };

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    const imageFile = event.target.files[0];
    let reader = new window.FileReader();
    reader.readAsDataURL(imageFile);
    let extension = imageFile.name.split('.').pop();
    reader.onloadend = () => {
      this.setState({
        imageFile,
        profile_image_url: reader.result,
        extension,
      });
    };
  }

  handleAvatarDeleteAlert = (flag = true) => this.setState({ deleteAvatarImageAlert: flag });

  handlePeriodicalSaga = (flag) => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
  };

  formatDataForSelect = (langs) => {
    const { __ } = this.props;
    let arrForSelect = [];
    Object.keys(langs).forEach((key) => {
      arrForSelect.push({ text: __(langs[key]), value: key });
    });
    return [{ text: '', value: null }, ...arrForSelect];
  };
  handleLogout() {
    this.props.dispatch(logout());
    const defaultRoute = getDefaultRoute(this.props.navConfig);
    forwardTo(defaultRoute.path);
  }
  setMobileCode = (val) => {
		this.setState({ mobile_code: val, mobile: `${val}${this.state.mobile_value}` });
	};
	setMobileValue = (val) => {
		this.handleInput('mobile_value', val);
	};
  render() {
    const { __, isProfileModalOpen, history, profile } = this.props;
    const {
      first_name,
      last_name,
      email,
      mobile,
      birthday,
      formErrors,
      is_subscribed,
      deleteAvatarImageAlert,
      locale,
      closeMyAccount,
      saveAccountIsOpen,
      profile_image_url,
      mobile_code,
			mobile_value,
    } = this.state;
    // const dateFormat = moment()._locale._longDateFormat.L
    const languages = Object.keys(getConfig().localization.supportedLocales);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDateValue = moment().subtract(18, 'years');
    const avatar = profile?.profile_image_url ? profile.profile_image_url : profile_image_url;
    const showProfileImage = getConfig()?.flags?.showProfileImage;
    return ( 
      <Loading>
        <Layout showHamburger color="transparent" headerTitle="My Account" scrollY={isWebConfig() ? false : true}>
          <div className="absolute-content scrollable-y">
            {showProfileImage ? (
                <div className="avatar-image-wrapper">
                  <div className="avatar-circle">
                    <IonAvatar className="profile-image-content">
                      {avatar ? (
                        <img
                          alt=""
                          src={avatar + (avatar.indexOf('http') !== -1 ? '?' + Date.now() : '')}
                          onClick={() => this.handleAvatarDeleteAlert(avatar)}
                        />
                      ) : null}
                      <div className="avatar-photo" onClick={async () => await this.takePicture()}>
                        <IonIcon icon={cameraIcon} size="small" color="dark" />
                      </div>
                    </IonAvatar>
                  </div>
              </div>
              ) : null
            }
            <div className="title-wrapper">
              <Title className="primary-color">{__('Your account')}</Title>  

              <div className="logout-btn" onClick={() => this.handleLogout()}>
                <NormalText className="block ">{__('Logout')}</NormalText>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-logout"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>
              </div>
            </div>
            <ValidateButton />
            <Spacer size={1} />
            <IonList>
              <div className="input-field-container">
                <NormalText>{__('First name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
              </div>
              <div className="input-field-container">
                <NormalText>{__('Last name')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                    clearInput
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
              </div>
              <div className="input-field-container">
                <NormalText>{__('Email')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper disabled-wrapper">
                  <IonInput
                    className="okx-font-secondary"
                    onIonInput={(e) => this.handleInput('email', e.target.value)}
                    clearInput
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                  ></IonInput>
                </IonItem>
              </div>
              {formErrors.email ? (
                <FieldError className="field-error" value={__(formErrors.email)} />
              ) : null}
             		{getConfig().flags.hasMobileCountryCode ? (
								<div className="input-field-container">
                  <NormalText>{__('Mobile number')}</NormalText>
									<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
								</div>
							) : (
								<div className="input-field-container">
									<NormalText>{__('Mobile number')}</NormalText>
									<IonItem lines="none" className="input-field-wrapper">
										<IonInput
											className="okx-font-secondary"
											onIonInput={(e) => this.handleInput('mobile', e.target.value)}
											clearInput
											required={false}
											type="tel"
											pattern="tel"
											inputmode="tel"
											onKeyPress={(e) => {
												const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
												let regex = new RegExp(reqexFormat);
												if (regex.test(e.key)) {
													return true;
												}
												e.preventDefault();
												return false;
											}}
											value={mobile}
										></IonInput>
										{formErrors.mobile ? <FieldError className="field-error" value={__(formErrors.mobile)} /> : null}
									</IonItem>
								</div>
							)}

              <div className="input-field-container">
                <NormalText>{__('Date of Birth')}</NormalText>
                <IonItem
                  lines="none"
                  className={`input-field-wrapper ${
                   profile.birthday !== null ? 'disabled-wrapper' : ''
                  }`}
                >
                  <DatePicker
                    className="data-picker-input okx-font-secondary"
                    cssClass={isNativeAndroid() ? "mobiscroll-date-picker" : ""}
                    display="bottom"
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    lang={profile.locale}
                    defaultValue={defaultDateValue}
                    max={yesterday}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd-mm-yy"
                    /* onBeforeShow={() => {
                      this.props.dispatch(beforeShowTimePicker());
                    }}
                    onClose={() => {
                      this.props.dispatch(beforeCloseTimePicker());
                      this.handlePeriodicalSaga(true);
                    }} */
                    // onShow={() => this.handlePeriodicalSaga(false)}
                  />
                </IonItem>
              </div>
              {languages.length <= 1 ? null : (
                <div className="input-field-container">
                  <NormalText>{__('Language')}</NormalText>
                  <IonItem lines="none" className="input-field-wrapper dropdown-field">
                    <SelectOption
                      cssClass="language-picker"
                      display="center"
                      onSet={(e, a) => this.handleLanguageInput(e, a)}
                      data={this.formatDataForSelect(getConfig().localization.supportedLocales)}
                      label="Location"
                      value={locale}
                      inputStyle="box"
                      placeholder={__('Select one')}
                      cancelText={__('Cancel')}
                      setText={__('OK')}
                      disabled={this.props.isShowTimePicker ? true : false}
                    />
                  </IonItem>
                </div>
              )}
              <Spacer size={1} />
              <StrongText className="primary-color">{__('Communication preferences')}</StrongText>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox
                  color="primary"
                  slot="start"
                  checked={is_subscribed}
                  onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                />
                <IonLabel className="ion-text-wrap">
                  <NormalText>
                    {__('I would like to receive') + ' ' + __('updates and offers via email')}
                  </NormalText>
                </IonLabel>
              </IonItem>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox color="primary" slot="start" checked={true} disabled={true} className="unclicked" />
                <IonLabel className="ion-text-wrap">
                  <NormalText>
                    {__('By signing up you agreed to our')}{' '}
                    <span className="link underlined" onClick={() => forwardTo('/terms')}>
                      {__('terms and conditions of service')}
                    </span>{' '}
                    {__('and')}{' '}
                    <span className="link underlined" onClick={() => forwardTo('/privacy')}>
                      {__('privacy policy')}
                    </span>
                  </NormalText>
                </IonLabel>
              </IonItem>
            </IonList>
            <Spacer />
            <IonButton
              expand="block"
              color="primary"
              className="uppercase okx-font-secondary"
              onClick={() => this.handleSave()}
            >
              {__('Save')}
            </IonButton>
            { isWeb() && (getConfig().flags.hasOrdering || getConfig().flags.hasGiftVouchers || getConfig().flags.hasMembership) ? (
              <IonButton
                color="primary"
                expand="block"
                fill="outline"
                className="uppercase okx-font-secondary"
                onClick={() => history.push('/cards', { addCardFromAccount: true })}
              >
                {__('Manage Payment Cards')}
              </IonButton>
            ) : null}
            {getConfig().flags.hasDeleteAccount && (
              <IonButton
                expand="block"
                fill="clear"
                color="danger"
                className="underlined"
                onClick={() => this.setState({ closeMyAccount: true })}
              >
                {__('Close my account')}
              </IonButton>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={isProfileModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isProfileModalOpen', false))}
          header={__('Success')}
          message={__('Profile is updated.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal('isProfileModalOpen', false)),
            },
          ]}
        />
        <IonAlert
          isOpen={deleteAvatarImageAlert}
          onDidDismiss={() => this.handleAvatarDeleteAlert(false)}
          header={__('Remove')}
          message={__('Do you want to remove profile image.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleAvatarDeleteAlert(false),
            },
            {
              text: __('Remove'),
              handler: () => this.removeProfileImage(),
            },
          ]}
        />
        <IonAlert
          isOpen={closeMyAccount}
          onDidDismiss={() => this.setState({ closeMyAccount: false })}
          header={__('Close Your Account')}
          message={__(
            'Are you sure you wish to close your account? Any points and vouchers you have earned will be permanently lost',
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ closeMyAccount: false }),
            },
            {
              text: __('OK'),
              handler: () => this.props.dispatch(deleteUser(profile.user_token)),
            },
          ]}
        />
        <IonAlert
          isOpen={saveAccountIsOpen}
          onDidDismiss={() => this.setState({ saveAccountIsOpen: false })}
          header={__('Save Your Account')}
          message={__(
            "Please double check your date of birth. You will not be able to change this once it's set",
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ saveAccountIsOpen: false }),
            },
            {
              text: __('OK'),
              handler: () => {
                this.setState({ saveAccountIsOpen: false });
                this.handleSave(true);
              },
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, isProfileModalOpen } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  const { navConfig } = state.common;
  return {
    auth,
    profile,
    isProfileModalOpen,
    isShowTimePicker,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Account));
