import { Capacitor } from '@capacitor/core';
import { IonAlert, IonButton, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, isPlatform } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import Modal from '../../components/modal';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import LibStripe from '../../lib/stripe';
import { withTranslation } from '../../lib/translate';
import { forwardTo, goBack, isDefined, isWebConfig } from '../../lib/utils';
import { logout, setCommonModal } from '../../store/actions';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import CheckoutPay from './CheckoutPay';
import './index.css';
import 'moment-timezone';
import { cardOutline } from 'ionicons/icons';
import DrawPayButton from '../../components/drawPayButton';

const { SelectOption } = Mobiscroll;
const { getMobile, setMobile, getOrderType, _getTotal } = Basket;
const isWeb = () => Capacitor.getPlatform() === 'web';

class Checkout extends React.Component {
	constructor(props) {
		super(props);
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile);
		}
		this.state = {
			selectedCard: null,
			collectedModal: false,
			driverModal: false,
			pickTime: null,
		};
	}

	componentDidMount() {
		const { profile } = this.props;
		const { google } = window
		window.googlePayClient = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' })
		if (profile.cardToken) {
			this.changeSelectedPaymentCard(profile.cardToken);
		}
		isWeb() && profile.is_guest &&
			!this.props?.location?.state?.cardAdded &&
			forwardTo('/card-add', {
				addCardFromCheckout: true,
				title: 'Checkout',
				fromTablePay: this.props?.location?.state?.fromTablePay,
			});

	}

	componentDidUpdate(prevProps) {
		if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
			this.changeSelectedPaymentCard(this.props.profile.cardToken);
		}
	}

	drawPayButton = () => {
		const { __ } = this.props;
		let ret = null;
		if (LibStripe.getStripeInstance() && Capacitor.getPlatform() !== 'web') {
			if (!isPlatform('ios')) {
				//android
				ret = (
					<IonButton expand="block" color="primary" onClick={() => Basket.createOrder('google')}>
						{__('Google Pay')}
					</IonButton>
				);
			} else {
				//ios
				ret = (
					<IonButton expand="block" color="primary" onClick={() => Basket.createOrder('apple')}>
						{__('Apple Pay')}
					</IonButton>
				);
			}
		}
		return ret;
	};

	changeSelectedPaymentCard = (cardId) =>
		this.setState({ selectedCard: cardId }, () => {
			Basket.changeSelectedCard(cardId);
		});

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.skipContactDetails) {
			forwardTo('/order-summary', { skipBackToThePreviousPage: true });
		} else if (this.props?.location?.state?.giftVoucher) {
			forwardTo('/gift-voucher', { voucher: this.props?.location?.state?.giftVoucher, hasEdit: true, redirectedFromCheckout: true  });
		} else if (this.props.location?.state?.fromTablePay) {
			goBack();
		} else if (this.props.location.pathname === '/checkout') {
			forwardTo('/order');
		} else {
			goBack();
		}
	};

	showCollectedModal = (modalOpen) => {
		this.setState({ collectedModal: modalOpen });
	};

	showDriverModal = (modalOpen) => {
		this.setState({ driverModal: modalOpen });
	};

	changeTime = (selectedTime, minDT) => {
		let h = parseInt(selectedTime.split(':')[0]);
		let m = parseInt(selectedTime.split(':')[1]);
		const formattedDT = moment(minDT).hours(h).minutes(m);
		Basket.setCollectionTime(formattedDT);
	};

	setPickTime = (inst, minDT) => {
		if (inst.getVal()) {
			this.changeTime(inst.getVal(), minDT);
			this.setState({ pickTime: inst.getVal() });
		}
	};

	render() {

		const { __, cards, clientSecret, dispatch, location, profile, deliveryTimeModalOpen, restaurants } = this.props;
		const { collectedModal, driverModal, pickTime } = this.state;
		let orderType = '';
		let price = '';
		let store = '';
		let snoozedTimes = '';
		let minDT = '';
		let timePickerOptions = [];
		const { payments } = getConfig()?.services;
		const { webPay } = payments;
		if (Basket.getDeliveryOption()?.id !== 'gift-vouchers') {
			orderType = getOrderType();
			price = _getTotal();
			store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant()?.id);
			if (orderType === 'Table') {
				snoozedTimes = [];
			} else {
				snoozedTimes = checkSnoozedTimes(store, Basket.order_type); // TODO Update
			}
			minDT = moment();
			if (Basket.getDeliveryOption()?.id === 'charter-delivery') {
				if (store && isDefined(store.charter_delivery_order_slot_lead_time)) {
					minDT.add(store.charter_delivery_order_slot_lead_time, 'minutes');
				}
			} else {
				if (store && isDefined(store.order_slot_lead_time)) {
					minDT.add(store.order_slot_lead_time, 'minutes');
				}
			}

			timePickerOptions = formatDataForTime(Basket.getRestaurant(), minDT, Basket.getRestaurant()?.id, Basket.getDeliveryOption()?.id === 'charter-delivery' ? true : false, false, snoozedTimes);
		} else {
			if (!Basket.is_gift && this.props?.location?.state?.giftVoucher) {
				Basket.setCollectionTime(null);
				Basket.addToBasket({
					item: {
						productPrice: this.props?.location?.state?.giftVoucher.points_value / 100,
						productName: 'Voucher',
					},
					quantity: 1,
				});
				Basket.setGift(this.props.location.state.giftVoucher);
				Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
			}
		}
		return (
			<>
				<Loading transparent>
					<Layout color="transparent" headerWithTitle={true} title={__('Checkout')} backHandler={this.backHandler}>
						<div className="flex-row-wrapper absolute-content">
							<Title className="web-only">{__('Checkout')}</Title>
							{/* <SmallText>{__('Use saved payment card')}</SmallText> */}
							{isWeb() ?
								<>
									<div className="scrollable-y">
										<IonList lines="none">
											<IonRadioGroup onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)} value={this.state.selectedCard}>
												{cards.map((card) => {
													const { id, last4, brand, exp_month, exp_year, name } = card;

													return (
														<IonItem lines="none" className="box-content checkout-card " key={id}>
															<IonIcon slot="start" icon={cardOutline}></IonIcon>

															<IonLabel className="ion-text-wrap">
																<NormalText className="single-item">{name}</NormalText>
																<StrongText className=" block">**** **** **** {last4}</StrongText>
																<NormalText>
																	{__(brand)} - {exp_month}/{exp_year}
																</NormalText>
															</IonLabel>
															<IonRadio
																color={isWebConfig() ? 'primary' : 'white'}
																slot="start"
																value={id}
																onIonSelect={() => {
																	this.setState({ selectedCard: id }, () => {
																		Basket.changeSelectedCard(id);
																	});
																}}
															/>
														</IonItem>
													);
												})}
											</IonRadioGroup>
										</IonList>
										<IonButton
											fill="clear"
											color="secondary"
											className="link underlined"
											onClick={() =>
												forwardTo('/card-add', {
													addCardFromCheckout: true,
													giftVoucher: this.props.giftVoucher ? this.props.giftVoucher : this.props?.location?.state?.giftVoucher ? this.props?.location?.state?.giftVoucher : false,
													fromTablePay: this.props?.location?.state?.fromTablePay,
												})
											}
										>
											{__((cards.length > 0 ? 'Or add another' : 'Add ') + ' payment card')}
										</IonButton>
									</div>

									<div className="flex-min">
										<IonButton
											disabled={cards.length === 0 || !this.state.selectedCard}
											className="uppercase okx-font-secondary"
											onClick={() => {
												Basket.setSelectedCard(this.state.selectedCard);
												!this.props.location?.state?.fromTablePay ? Basket.createOrder() : Basket.createBillPay();
											}}
											expand="block"
										>
											{__('Pay')}
										</IonButton>
										{getConfig().flags.payOnCollection && (Basket.getDeliveryOption()?.id == 'scheduled-collection' || Basket.getDeliveryOption()?.id == 'collection') ? (
											<IonButton onClick={() => this.showCollectedModal(true)} expand="block" color="primary">
												{`${__('Pay')} ${price} ${__('On Collection')}`}
											</IonButton>
										) : null}
										{getConfig().flags.payTheDriver && orderType === 'Delivery' ? (
											<IonButton onClick={() => this.showDriverModal(true)} expand="block" color="primary">
												{`${__('Pay')} ${price} ${__('To The Driver')}`}
											</IonButton>
										) : null}
										{/* {!this.props?.location?.state?.fromTablePay && (
									<>
										{getConfig().prebuildPayment?.isVisible ? (
											<IonButton
												style={{ marginBottom: '10px' }}
												className="uppercase okx-font-secondary"
												onClick={() => {
													Basket.createStripeOrder(getConfig().prebuildPayment?.label);
												}}
												expand="block"
											>
												{__(getConfig().prebuildPayment?.label)}
											</IonButton>
										) : null}
									</>
								)} */}
										{getConfig()?.prebuildPayment?.isVisible ? (
											<IonButton
												style={{ marginBottom: '10px' }}
												className="uppercase okx-font-secondary"
												onClick={() => {
													Basket.createStripeOrder(getConfig().prebuildPayment?.label);
												}}
												expand="block"
											>
												{__(getConfig().prebuildPayment?.label)}
											</IonButton>
										) : null}

										{/* {isWeb() && webPay ? ( */}
										<CheckoutPay
											isBillPay={this.props?.location?.state?.fromTablePay ? true : false}
											clientSecret={clientSecret}
											dispatch={dispatch}
											__={__}
											location={location}
											profile={profile}
										/>
										{/* ) : (
									<DrawPayButton __={__} googlePayClient={window.googlePayClient} />
								)} */}
									</div>
								</>
								: null
							}
							{!isWeb() ? (
								<>
									{getConfig()?.prebuildPayment?.showOnNative ? (
										<IonButton
											style={{ marginBottom: '10px' }}
											className="uppercase okx-font-secondary"
											onClick={() => {
												Basket.createStripeOrder(getConfig().prebuildPayment?.label);
											}}
											expand="block"
										>
											{__(getConfig().prebuildPayment?.label)}
										</IonButton>
									) : null}
									{!isWeb() && getConfig().nativePayment?.isVisible ? (
										<IonButton
											style={{ marginBottom: '10px' }}
											className="uppercase okx-font-secondary"
											onClick={() => {
												Basket.createOrder('NativePayment');
											}}
											expand="block"
										>
											{__(getConfig().nativePayment?.label)}
										</IonButton>
									) : null}
									{getConfig().flags.hasYocoPayment ? (
										<IonButton
											style={{ marginBottom: '10px' }}
											className="uppercase okx-font-secondary"
											onClick={() => {
												Basket.createYocoOrder('yoco');
											}}
											expand="block"
										>
											{__('Pay With Yoco')}
										</IonButton>
									) : null}
								</>) : null
							}
							{this.props.profile.is_guest && (
								<IonButton
									expand="block"
									fill="clear"
									onClick={() => {
										this.props.dispatch(logout());
									}}
								>
									{__('Cancel')}
								</IonButton>
							)}
						</div>
					</Layout>
					<IonAlert
						isOpen={collectedModal}
						onDidDismiss={() => this.showCollectedModal(false)}
						header={__('Pay on Collection')}
						message={__('You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment.')}
						buttons={[
							{
								text: __('Cancel'),
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => this.showCollectedModal(false),
							},
							{
								text: __('Confirm'),
								handler: () => {
									Basket.createOrder('collectedPay');
									this.showCollectedModal(false);
								},
							},
						]}
					/>
					<IonAlert
						isOpen={driverModal}
						onDidDismiss={() => this.showDriverModal(false)}
						header={__('Pay to the Driver')}
						message={__('You agree to pay the driver the amount outstanding on delivery. Earned loyalty will be updated  after payment.')}
						buttons={[
							{
								text: __('Cancel'),
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => this.showDriverModal(false),
							},
							{
								text: __('Confirm'),
								handler: () => {
									Basket.createOrder('driverPay');
									this.showDriverModal(false);
								},
							},
						]}
					/>
				</Loading>
				<Modal className="delivery-time-modal" isOpen={deliveryTimeModalOpen} onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}>
					<Title>{__('Select Collection Time')}</Title>
					<NormalText>{__('Please select different time')}</NormalText>
					<Spacer />
					<SelectOption
						display="inline"
						onSet={(e, inst) => this.setPickTime(inst, minDT)}
						data={timePickerOptions}
						label="Location"
						value={pickTime}
						inputStyle="box"
						placeholder={__('Select Collection Time')}
						setText={__('OK')}
						cancelText={__('Cancel')}
						onInit={() => {
							if (timePickerOptions.length > 0) {
								const firstAvailableTime = timePickerOptions.find((i) => i.value !== null);
								if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
									this.changeTime(firstAvailableTime.value, minDT);
								}
							}
						}}
					/>
					<Spacer />
					<IonButton expand="block" color="primary" className="uppercase okx-font-secondary" onClick={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}>
						{__('Continue')}
					</IonButton>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		cards: store.orders.cards || [],
		profile: store.profile.profile || {},
		clientSecret: store.orders.clientSecret,
		deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
		restaurants: store.restaurants.restaurants || [],
	};
};

export default connect(mapStateToProps)(withTranslation(Checkout));
